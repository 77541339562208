import React, { useEffect, useState } from 'react';
import axiosInstance from '../components/axiosInstance';
const AccountPage = () => {
    const [user, setUser] = useState({
        email: '',
        first_name: '',
        last_name: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const API_URL = process.env.REACT_APP_API_URL;
    console.log(API_URL, 'nnnnnn');
    useEffect(() => {
        const fetchUserData = async () => {
            // assuming token is stored in localStorage
            try {
                const response = await axiosInstance.get(`/users/me`, {

                });
                console.log(response.status, 'nnnnnnnn')
                if (response.status !== 200) {
                    throw new Error('Failed to fetch user data');
                }

                const data = response.data;
                setUser(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    if (loading) {
        return <div className="text-center mt-10 text-gray-500">Loading...</div>;
    }

    if (error) {
        return <div className="text-center mt-10 text-red-500">{error}</div>;
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="bg-white p-8 rounded-lg shadow-lg w-80">
                <h2 className="text-2xl font-semibold text-center mb-4">Account Information</h2>
                <div className="text-lg">
                    <p className="mb-2">
                        <span className="font-semibold">Email:</span> {user.email}
                    </p>
                    <p className="mb-2">
                        <span className="font-semibold">First Name:</span> {user.first_name}
                    </p>
                    <p className="mb-2">
                        <span className="font-semibold">Last Name:</span> {user.last_name}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AccountPage;
