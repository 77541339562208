import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
    const isAuthenticated = localStorage.getItem("access_token") && localStorage.getItem("isLoggedIn") === "true";

    return isAuthenticated ? element : <Navigate to="/signin" replace />;
};

export default ProtectedRoute;
