import React from 'react';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
const DraftsPagination = ({ currentPage, totalDrafts, onPageChange }) => {
    const location = useLocation();
    const isDocumentPage = location.pathname === "/document-page";
    const totalPages = Math.ceil(totalDrafts / (isDocumentPage ? 5 : 10));

    const generatePageNumbers = () => {
        if (totalPages <= 5) {
            return [...Array(totalPages)].map((_, i) => i + 1);
        }

        if (currentPage <= 3) {
            return [1, 2, 3, 4, '...', totalPages];
        }

        if (currentPage >= totalPages - 2) {
            return [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
        }

        return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
    };

    const handlePageChange = (page) => {
        if (page === '...') return;
        onPageChange(page);
    };


    return (
        <div
            className="flex items-center py-2 font-dmsans justify-between">
            <span className="text-[#868CA2] font-medium text-sm font-dmsans pl-1">
                <span className="mr-1">{Math.min(currentPage * (isDocumentPage ? 5 : 10), totalDrafts)}</span>
                <span className="mr-1">Of</span>
                <span>{totalDrafts} Items</span>
            </span>
            <div className="flex items-center space-x-2">
                <button
                    data-testid="pagination-left"
                    className={`px-1 py-1 rounded-md ${currentPage === 1 ? 'text-[#868CA2] cursor-not-allowed' : 'text-gray-700'}`}
                    onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft className="text-[#000000] w-4 h-4" />
                </button>
                {generatePageNumbers().map((pageNumber, index) => (
                    <button
                        key={index}
                        className={`rounded-sm w-6 h-6 ${currentPage === pageNumber
                            ? 'bg-[#000000] text-[#ffffff]'
                            : 'text-[#868CA2]'
                            }`}
                        onClick={() => handlePageChange(pageNumber)}
                        title="pagination-number"
                    >
                        {pageNumber}
                    </button>
                ))}
                <button
                    data-testid="pagination-right"
                    className={`px-1 py-1 rounded-md ${currentPage === totalPages ? 'text-[#868CA2] cursor-not-allowed' : 'text-[#868CA2]'}`}
                    onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <FaChevronRight className="text-[#000000] w-4 h-4" />
                </button>
            </div>

        </div>
    );
};

export default DraftsPagination;
