import logo from '../src/assets/logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KnowledgeBase from './pages/Knowledgebase';
import ProtectedRoute from './components/Protectedroute';
import CreateAssistant from './pages/Assistant';
import Signup from './pages/Signup';
import SignIn from './pages/Login';
import Layout from './pages/Layout';
import Files from './pages/Files';
import Landing from './pages/Landing';
import ConversationInterface from './pages/Chatinterface';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/Resetpassword';
import AccountPage from './pages/Account';
import Billing from './pages/Integrations';
import VerifyEmail from './pages/Verifyemail';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route element={<Layout />}>

          <Route path="/knowledgebase" element={<ProtectedRoute element={<KnowledgeBase />} />} />
          <Route
            path="/assistants"
            element={<ProtectedRoute element={<CreateAssistant />} />}
          />
          {/* Dynamic Route for Assistant Details */}
          <Route
            path="/assistants/:assistant_id"
            element={<ProtectedRoute element={<CreateAssistant />} />}
          />
          <Route path="/files" element={<ProtectedRoute element={<Files />} />} />
          <Route path="/assistants/:assistant_id/chat" element={<ProtectedRoute element={<ConversationInterface />} />} />
          <Route path="/accounts-page" element={<ProtectedRoute element={<AccountPage />} />} />
          <Route path="/billings" element={<ProtectedRoute element={<Billing />} />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
