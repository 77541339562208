import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { googleLogin } from "./login";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import LoadingAnimation from "../assets/LoadingAnimation.svg";
// import { toast } from "react-toastify";
function GoogleSignInButton(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const API_URL = process.env.REACT_APP_API_URL;
    const [loading, setLoading] = useState(false);
    const codeProcessed = useRef(false); // Use useRef to track if the code has been processed

    // Function to handle Google Sign-In by fetching the authorization URL from the backend
    const handleGoogleSignIn = async () => {
        try {
            // Call the backend to get the Google OAuth authorization URL
            const response = await axios.get(`${API_URL}/auth/google/authorize`);
            const authorizationUrl = response.data.authorization_url;
            console.log(response);
            // Redirect the user to the Google OAuth authorization URL
            window.location.href = authorizationUrl;



        } catch (error) {
            // toast.error(`${error.response?.data?.detail}`);
            setLoading(false);
        }
    };

    const handleOAuthCallback = async (code, state) => {
        if (code && state) {
            setLoading(true);
            // codeProcessed.current = true;
            try {
                // Send the authorization code to your backend for token exchange
                await googleLogin(code, state);

                // Redirect to the homepage or another page after successful login
                navigate("/assistants");
            } catch (error) {
                console.log("Error during OAuth callback:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        const state = urlParams.get("state")
        console.log(code);
        // If a code exists and hasn't been processed yet, handle the OAuth callback
        if (code && state && !codeProcessed.current) {
            codeProcessed.current = true;
            handleOAuthCallback(code, state);
        }
    }, [navigate]);



    return (
        <div className="flex mt-3">
            <button
                id="google-login-btn"
                onClick={handleGoogleSignIn}
                className={`py-[10px] bg-[#F8FAFC] border border-[#0000001A] gap-2 flex justify-center items-center h-[58px] w-[430px] border-[#39F6A3] border-opacity-10 rounded-[5px] ${(props?.isDisabled) ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                disabled={props?.isDisabled || loading}
            >
                {loading ? (
                    <span className="h-full w-14 flex justify-center items-center rounded-md">
                        {/* <img src={LoadingAnimation} alt="loading..." /> */}
                    </span>

                ) : (
                    <>
                        <span className="h-full w-8 flex justify-center items-center rounded-md">
                            <FcGoogle className="w-[25px] h-[25px]" />
                        </span>
                        <div className="text-[15px] h-[25px] font-semibold font-dmsans leading-normal w-[163px] text-[#1E293B]">
                            Sign in with Google
                        </div>
                    </>
                )}
            </button>
        </div>
    );
}
export default GoogleSignInButton;