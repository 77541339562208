import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleSignInButton from '../components/GoogleSigninbutton';
import logo from '../assets/logo.svg';
import { toast } from "react-toastify";
const SignUp = () => {
    const [formData, setFormData] = useState({

        email: '',
        first_name: '',
        last_name: '',
        password: ''
    });
    const API_URL = process.env.REACT_APP_API_URL;

    const navigate = useNavigate();
    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    // handle form submission
    const handleFormSubmit = (event) => {
        event.preventDefault();
        fetch(`${API_URL}/auth/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    toast.success('User registered successfully');
                    // Trigger /request-verify-token
                    fetch(`${API_URL}/auth/request-verify-token`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ email: formData.email }),
                    })
                        .then((verifyResponse) => verifyResponse.json())
                        .then((verifyData) => {
                            toast.success('Verification email sent');
                            navigate('/signin'); // Redirect to sign-in after verification trigger
                        })
                        .catch((verifyError) => console.error('Verification error:', verifyError));
                }
            })
            .catch((error) => console.error('Error:', error));
    };

    return (
        <div className="relative flex items-center justify-center min-h-screen bg-black">
            <div
                className="absolute inset-0 bg-cover bg-center bg-black"

            >
                <div className="absolute top-0 w-full flex flex-col items-center mt-10">
                    <img
                        src={logo}
                        alt="Success Tick"
                        className="h-[45px] w-[45px]"
                    />
                    <h2 className="text-xl font-semibold text-white mt-2">Welcome to Pragya.ai</h2>
                </div>
            </div>
            <div className="relative z-10 w-full max-w-md p-8 bg-[#1A1B1EFF] rounded-lg shadow-md">
                <h1 className="text-2xl  mb-6 text-center text-white font-bold">Sign Up</h1>
                <form className="space-y-6" onSubmit={handleFormSubmit}>
                    <div>
                        <label htmlFor="first_name" className="block text-l  text-white font-bold mb-2">First Name</label>
                        <input
                            id="first_name"
                            type="text"
                            placeholder="Enter your first name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleInputChange}
                            required
                            minLength="3"
                            className="w-full p-3 border border-gray-300 rounded-lg bg-black text-white focus:outline-white focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block text-l  text-white font-bold mb-2">Last Name</label>
                        <input
                            id="last_name"
                            type="text"
                            placeholder="Enter your last name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                            required
                            minLength="3"
                            className="w-full p-3 border border-gray-300 rounded-lg bg-black text-white focus:outline-white focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-l  text-white font-bold mb-2">Email</label>
                        <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                            title="Please enter a valid email address."
                            className="w-full p-3 border border-gray-300 rounded-lg bg-black text-white focus:outline-white focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="username" className="block text-sm  text-blue-900 font-bold mb-2">Username</label>
                        <input
                            id="username"
                            type="text"
                            placeholder="Enter your username"
                            name="username"
                            // value={formData.username}
                            onChange={handleInputChange}
                            required
                            title="Username must be at least 6 characters long and contain only alphanumeric characters."
                            className="w-full p-3 border border-gray-300 rounded-lg bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="password" className="block text-l  text-white font-bold mb-2">Password</label>
                        <input
                            id="password"
                            type="password"
                            placeholder="Enter your password"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required
                            title="Password must be at least 6 characters long."
                            minLength="6"
                            className="w-full p-3 border border-gray-300 rounded-lg bg-black text-white focus:outline-white focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-3 bg-[#EFEEEA]  text-black font-bold rounded-lg focus:outline-none focus:ring-2 "
                    >
                        Sign Up
                    </button>
                </form>
                <GoogleSignInButton />
                <div className="mt-6 text-center">
                    <p className="text-sm text-white">
                        Already have an account?{' '}
                        <a href="/signin" className=" text-green-300 font-bold hover:underline">Sign In</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignUp;