import React from 'react';
import codesandbox from "../assets/codesandbox.svg"
const CreateKnowledgeBase = ({ onCreateKnowledgeBase }) => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-stone-100">
            <div className="text-center">
                <h1 className="text-2xl font-semibold text-gray-900">
                    Start Your Own Knowledge Base
                </h1>
                <p className="text-gray-500 mt-2">
                    Knowledge bases allow you to create LLM workflows using your own documents.
                </p>
                <div className="flex justify-center mt-6">
                    <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center">
                        {/* Replace the SVG below with your desired icon */}
                        <img src={codesandbox} alt="Success Tick" className="h-[100px] w-[100px] mb-4" />
                    </div>
                </div>
                <button
                    className="mt-8 px-6 py-3 bg-black text-white text-sm font-medium rounded hover:bg-gray-800 transition"
                    onClick={onCreateKnowledgeBase}
                >
                    Create New Knowledge Base
                </button>
            </div>
        </div>
    );
};

export default CreateKnowledgeBase;
