import React from 'react'
// import TickIcon from "../assets/ExamplesPageAssets/TickIcon.svg";
import PopupModal from "../components/PopupModal";
const SucessModal = ({ text, handleModal }) => {
    return (
        <PopupModal closeModal={handleModal} className="bg-[#F9F9F9]">
            <div className="flex flex-col justify-center items-center h-[170px] w-[318px]">
                {/* <img src={TickIcon} alt="Success Tick" className="h-[48px] w-[48px] mb-4" /> */}
                <p className="text-[#101828] font-dmsans text-lg mb-4">{text}</p>
                <button
                    className="w-[310px] h-[32px] rounded-[1px] text-center bg-[#000000] text-[#F3F3F3] text-[16px]"
                    onClick={handleModal}
                >
                    Done
                </button>
            </div>
        </PopupModal>
    )
}

export default SucessModal;