import React from 'react';
import Sidebar from '../components/Sidebar'; // Import your Sidebar component
import { Outlet } from 'react-router-dom';  // Outlet is used to render the child route

const Layout = () => {
    return (
        <div className="flex">
            {/* Sidebar */}
            <Sidebar />

            {/* Main content */}
            <div className="flex-grow p-4 bg-white h-screen">
                {/* Outlet renders the current page */}
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
