import { useSearchParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

const VerifyEmail = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();


    useEffect(() => {
        if (token) {
            fetch(`${API_URL}/auth/verify`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log('Verification successful:', data);
                    // Redirect to sign-in page
                    navigate('/signin');
                })
                .catch((error) => {
                    console.error('Verification error:', error);
                });
        }
    }, [token]);

    return <div>Verifying email...</div>;
};

export default VerifyEmail;
