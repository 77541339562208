import React from 'react';

const DocumentListShimmer = ({ items, show = true }) => {
    return show ? (
        <div className="space-y-2">
            {[...Array(items)].map((_, index) => (
                <div
                    key={index}
                    className="flex flex-col md:flex-row items-center justify-between p-2 rounded-md border border-gray-200 animate-pulse bg-white h-[60px] md:h-[50px]"
                >
                    <div className="flex items-center space-x-4 md:space-x-20 w-full md:w-auto md:ml-2">
                        <div className="h-4 w-16 md:w-20 bg-gray-200 rounded mr-4"></div>
                        <div className="h-4 w-36 md:w-48 bg-gray-200 rounded"></div>
                    </div>
                    <div className="flex items-center space-x-4 md:space-x-20 mt-2 md:mt-0 w-full md:w-auto mx-3 justify-center md:justify-end">
                        <div className="h-4 w-12 md:w-16 bg-gray-200 rounded mr-4"></div>
                        <div className="h-6 w-6 bg-gray-200 rounded"></div>
                        <div className="h-6 w-6 bg-gray-200 rounded"></div>
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div className="flex justify-center w-full h-full">
            <div className="space-y-4 w-full px-4"> {/* Adjusted space-y to 1 */}
                {[...Array(items)].map((_, idx) => (
                    <div key={idx} className="flex justify-between items-center animate-pulse bg-white p-2 h-[50px] w-full">
                        <div className="flex space-x-8 w-full"> {/* Adjusted space-x to 8 */}
                            <div className="w-[50%] h-6 bg-gray-300 rounded mr-20"></div>
                            <div className="w-1/6 h-6 bg-gray-300 rounded"></div>
                        </div>
                        <div className="flex w-1/6 "> {/* Adjusted space-x to 1 */}
                            <div className="h-6 bg-gray-300 rounded w-full"></div>
                        </div>
                    </div>
                ))}
            </div>


        </div>



    );
};

export default DocumentListShimmer;
