// axiosInstance.js
import axios from "axios";
// import { getRefreshToken } from "./login"; // Adjust the import based on your project structure

const API_URL = process.env.REACT_APP_API_URL;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Ensures cookies are sent with requests
});

// Request interceptor to add the Authorization header to each request
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
const refreshAccessToken = async () => {
    try {
        const response = await axios.post(`${API_URL}/auth/jwt/refresh`, {}, {
            withCredentials: true, // Include cookies for the refresh token
        });

        // Update the access token in local storage
        const newAccessToken = response.data.access_token;
        localStorage.setItem("access_token", newAccessToken);
        return newAccessToken;
    } catch (error) {
        console.error("Failed to refresh access token:", error);
        return null;
    }
};

// Response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is 401 and the request hasn't been retried yet
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const newAccessToken = await refreshAccessToken();

            if (newAccessToken) {
                // Update the Authorization header with the new token
                originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest); // Retry the original request
            } else {
                // If refreshing the token fails, clear storage and redirect to login
                localStorage.clear();
                window.location.href = "/signin";
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;


// import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL;

// // Create an Axios instance
// const axiosInstance = axios.create({
//     baseURL: API_URL,
//     withCredentials: true, // Ensures cookies are sent with requests
// });

// // Request interceptor to add the Authorization header to each request
// axiosInstance.interceptors.request.use(
//     (config) => {
//         const token = localStorage.getItem("access_token");
//         if (token) {
//             config.headers["Authorization"] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// // Refresh token function
// const refreshAccessToken = async () => {
//     try {
//         const response = await axios.post(`${API_URL}/auth/jwt/refresh`, {}, {
//             withCredentials: true, // Include cookies for the refresh token
//         });

//         // Update the access token in local storage
//         const newAccessToken = response.data.access_token;
//         localStorage.setItem("access_token", newAccessToken);
//         return newAccessToken;
//     } catch (error) {
//         console.error("Failed to refresh access token:", error);
//         return null;
//     }
// };

// // Response interceptor to handle 401 Unauthorized errors
// axiosInstance.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;

//         // Check if the error is 401 and the request hasn't been retried yet
//         if (error.response && error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;

//             const newAccessToken = await refreshAccessToken();

//             if (newAccessToken) {
//                 // Update the Authorization header with the new token
//                 originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
//                 return axiosInstance(originalRequest); // Retry the original request
//             } else {
//                 // If refreshing the token fails, clear storage and redirect to login
//                 localStorage.clear();
//                 window.location.href = "/signin";
//             }
//         }

//         return Promise.reject(error);
//     }
// );

// export default axiosInstance;
