import { configureStore } from "@reduxjs/toolkit";

import activeUserSlice from "./userSlice.js";
import sidebar from "./sidebarSlice.js";
import notificationSlice from "./notificationSlice.js";

const store = configureStore({
  reducer: {
    activeUser: activeUserSlice,
    sidebar: sidebar,
    notification:notificationSlice
    
  },
});
export default store;
