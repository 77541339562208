import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../components/axiosInstance.js";
import CreateKnowledgeBase from "../components/createknowledgebase.jsx";
import codesandbox from "../assets/codesandbox.svg"

const KnowledgeBase = () => {
    const [pdfList, setPdfList] = useState([]);
    const [selectedPdfs, setSelectedPdfs] = useState([]);
    const [knowledgeBases, setKnowledgeBases] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const [knowledgeBaseName, setKnowledgeBaseName] = useState('');
    const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingKnowledgeBase, setEditingKnowledgeBase] = useState(null);
    const [newKnowledgeBaseName, setNewKnowledgeBaseName] = useState('');
    const [loading, setLoading] = useState(false);
    const [kbno, setkbno] = useState(null);
    const [isKnowledgeBasesLoaded, setIsKnowledgeBasesLoaded] = useState(false);
    const [folders, setFolders] = useState([]);
    const [folderPdfs, setFolderPdfs] = useState({});
    const [expandedFolders, setExpandedFolders] = useState([]);

    useEffect(() => {
        fetchData();

    }, [pageNum]);
    useEffect(() => {

        fetchKnowledgeBases();
    }, []);

    useEffect(() => {
        fetchFolders();

    }, []);
    const fetchFolders = async () => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/folders`);
            setFolders(response.data);
        } catch (error) {
            toast.error("Error fetching folders");
        }
    };

    // Fetch ungrouped PDFs


    // Fetch PDFs in a folder
    const fetchFolderPdfs = async (folderId) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/folders/${folderId}/documents`);
            setFolderPdfs((prev) => ({ ...prev, [folderId]: response.data }));
        } catch (error) {
            toast.error("Error fetching folder PDFs");
        }
    };

    // Toggle folder expansion
    const toggleFolder = (folderId) => {
        if (expandedFolders.includes(folderId)) {
            setExpandedFolders(expandedFolders.filter((id) => id !== folderId));
        } else {
            setExpandedFolders([...expandedFolders, folderId]);
            if (!folderPdfs[folderId]) {
                fetchFolderPdfs(folderId);
            }
        }
    };
    const fetchData = async () => {
        setIsFetching(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/user/documents?page=${pageNum}&page_size=5`, {

            });

            const data = response.data.documents;

            setPdfList(data);
            setIsFetching(false);
        } catch (error) {
            toast.error(error.response?.data?.detail);
            setIsFetching(false);
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    const fetchKnowledgeBases = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(`${API_URL}/v1/user/knowledge-bases`);
            setKnowledgeBases(response.data);
            console.log(response.data);
            setIsKnowledgeBasesLoaded(true);
        } catch (error) {
            toast.error("Error fetching knowledge bases");
        }
        setLoading(false);
    };
    // Handle checkbox change
    const handleCheckboxChange = (pdfId) => {
        setSelectedPdfs((prev) =>
            prev.includes(pdfId)
                ? prev.filter((id) => id !== pdfId)
                : [...prev, pdfId]
        );
    };

    // Create knowledge base
    const handleCreateKnowledgeBase = async () => {
        try {
            console.log(knowledgeBaseName);
            console.log(selectedPdfs);
            if (setSelectedPdfs([])) {
                toast.error("Please select a folder.");
                return;
            }
            const response = await axiosInstance.post(`${API_URL}/v1/user/create-knowledge-base`, {
                name: knowledgeBaseName,
                pdf_ids: selectedPdfs
            });

            const pdfDetails = selectedPdfs.map(pdfId => {
                const pdf = pdfList.find((pdf) => pdf.id === pdfId);
                return pdf ? { id: pdf.id, name: pdf.name } : {};
            });

            const newKnowledgeBase = {
                id: response.data.knowledge_base_id,
                name: knowledgeBaseName,
                pdfs: pdfDetails // Include the actual PDF details
            };
            setKnowledgeBases([...knowledgeBases, newKnowledgeBase]);
            setShowCreateModal(false);
            setSelectedPdfs([]);
        } catch (error) {
            toast.error("Error creating knowledge base:", error);
        }
    };

    // Show PDFs of a specific knowledge base
    const handleShowKnowledgeBase = (knowledgeBase) => {
        setCurrentKnowledgeBase(knowledgeBase);
        setShowPdfModal(true);
    };

    const handleDeleteKnowledgeBase = async (id) => {
        try {
            await axiosInstance.delete(`${API_URL}/v1/user/delete-knowledge-base/${id}`);
            setKnowledgeBases(knowledgeBases.filter((kb) => kb.id !== id));
            toast.success("Knowledge base deleted successfully");
        } catch (error) {
            toast.error("Error deleting knowledge base");
        }
    };
    const handleEditKnowledgeBase = (kb) => {
        setEditingKnowledgeBase(kb);
        setNewKnowledgeBaseName(kb.name);
        setShowEditModal(true);
    };

    // Save Edited Knowledge Base
    const handleSaveEdit = async () => {
        if (!editingKnowledgeBase) return;
        try {
            await axiosInstance.put(`${API_URL}/v1/user/update-knowledge-base/${editingKnowledgeBase.id}`, {
                name: newKnowledgeBaseName
            });
            setKnowledgeBases(knowledgeBases.map((kb) =>
                kb.id === editingKnowledgeBase.id ? { ...kb, name: newKnowledgeBaseName } : kb
            ));
            setShowEditModal(false);
            toast.success("Knowledge base updated successfully");
        } catch (error) {
            toast.error("Error updating knowledge base");
        }
    };
    if (loading) {
        // Show a loader while data is being fetched
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="text-gray-500 text-xl">Loading...</div>
            </div>
        );
    }
    return (
        <div
            className={`flex absolute inset-0  bg-[#EFEEEA]  flex-col  ${isSidebarOpen ? 'ml-[280px]' : 'ml-[80px]'} transition-all duration-300 pt-[0px]`}
        >
            <div className="m-5  mb-5">


                {/* Create Knowledge Base Card */}


                {/* List of Knowledge Bases */}
                {(knowledgeBases?.length === 0) ? (
                    <CreateKnowledgeBase onCreateKnowledgeBase={() => setShowCreateModal(true)} />
                ) : (
                    <div >
                        <div className="flex items-center justify-between">
                            {/* Text aligned to the left */}
                            <h5 className="text-xl font-semibold">Create Knowledge Base</h5>

                            {/* Button aligned to the right */}
                            <button className="bg-black text-white py-2 px-4 rounded" onClick={() => setShowCreateModal(true)}>
                                New Knowledgebase
                            </button>
                        </div>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">


                            {knowledgeBases?.map((kb) => (

                                <div
                                    key={kb.id}
                                    className="cursor-pointer bg-white shadow-md rounded-lg p-6 hover:bg-gray-100"
                                >

                                    <div className="flex items-center space-x-4">
                                        {/* Icon */}
                                        <img
                                            src={codesandbox}
                                            alt="Success Tick"
                                            className="h-[45px] w-[45px]"
                                        />

                                        {/* Text Content */}
                                        <div>
                                            <h5 className="text-lg font-semibold">{kb.name}</h5>
                                            <p
                                                className="text-sm text-gray-500"
                                                onClick={() => handleShowKnowledgeBase(kb)}
                                            >
                                                PDFs associated
                                            </p>
                                        </div>
                                    </div>

                                    {/* Action Buttons */}
                                    <div className="mt-4">
                                        {/* Edit Button */}
                                        <button
                                            className="bg-black text-white px-2 py-1 mx-2 rounded "
                                            onClick={() => handleEditKnowledgeBase(kb)}
                                        >
                                            Edit
                                        </button>

                                        {/* Delete Button */}
                                        <button
                                            className="bg-black text-white px-2 py-1 rounded "
                                            onClick={() => handleDeleteKnowledgeBase(kb.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                )}


                {/* Modal for Creating Knowledge Base */}
                {showCreateModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Create Knowledge Base</h3>

                            {/* Knowledge Base Name Input */}
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">Knowledge Base Name</label>
                                <input
                                    type="text"
                                    value={knowledgeBaseName}
                                    onChange={(e) => setKnowledgeBaseName(e.target.value)}
                                    placeholder="Enter a name"
                                    className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-black"
                                />
                            </div>

                            {/* Ungrouped PDFs */}
                            <div className="mb-4">
                                <h4 className="font-medium">Files</h4>
                                <div className="max-h-60 overflow-y-auto">
                                    {pdfList.map((pdf) => (
                                        <div key={pdf.id} className="flex items-center mb-2">
                                            <input
                                                type="checkbox"
                                                className="mr-3"
                                                checked={selectedPdfs.includes(pdf.id)}
                                                onChange={() => handleCheckboxChange(pdf.id)}
                                            />
                                            <label>{pdf.name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Folders */}
                            <div className="mb-4">
                                <h4 className="font-medium">Folders</h4>
                                {folders.map((folder) => (
                                    <div key={folder.id}>
                                        <div
                                            className="cursor-pointer text-black"
                                            onClick={() => toggleFolder(folder.id)}
                                        >
                                            {folder.name}
                                        </div>
                                        {expandedFolders.includes(folder.id) && (
                                            <div className="ml-4">
                                                {folderPdfs[folder.id] ? (
                                                    folderPdfs[folder.id].map((pdf) => (
                                                        <div key={pdf.id} className="flex items-center mb-2">
                                                            <input
                                                                type="checkbox"
                                                                className="mr-3"
                                                                checked={selectedPdfs.includes(pdf.id)}
                                                                onChange={() => handleCheckboxChange(pdf.id)}
                                                            />
                                                            <label>{pdf.name}</label>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>Loading...</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex justify-end mt-6">
                                <button
                                    className="bg-black text-white px-4 py-2 rounded mr-3"
                                    onClick={handleCreateKnowledgeBase}
                                    disabled={selectedPdfs.length === 0 || !knowledgeBaseName}
                                >
                                    Create
                                </button>
                                <button
                                    className="bg-white text-black px-4 py-2 rounded border-2 border-current"
                                    onClick={() => setShowCreateModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal to Show PDFs in a Knowledge Base */}
                {showPdfModal && currentKnowledgeBase && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">{currentKnowledgeBase.name}</h3>
                            <div>
                                {currentKnowledgeBase.pdfs.map((pdf) => (
                                    <p key={pdf.id}>{pdf.name}</p>
                                ))}
                            </div>
                            <button onClick={() => setShowPdfModal(false)} className="bg-gray-500 text-white px-4 py-2 rounded mt-4">
                                Close
                            </button>
                        </div>
                    </div>
                )}

                {showEditModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                        <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
                            <h3 className="text-xl font-semibold mb-4">Edit Knowledge Base</h3>
                            <input
                                type="text"
                                value={newKnowledgeBaseName}
                                onChange={(e) => setNewKnowledgeBaseName(e.target.value)}
                                placeholder="Enter a new name"
                                className="w-full px-4 py-2 border rounded mb-4"
                            />
                            <div className="flex justify-end">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                    onClick={handleSaveEdit}
                                >
                                    Save
                                </button>
                                <button
                                    className="bg-gray-500 text-white px-4 py-2 rounded"
                                    onClick={() => setShowEditModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KnowledgeBase;
