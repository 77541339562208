import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const googleLogin = async (code, state) => {

    let res = await axios.get(`${API_URL}/auth/google/callback?code=${code}&state=${state}`, {
        withCredentials: true
    });
    console.log(res);
    localStorage.setItem("access_token", res.data.access_token);
    localStorage.setItem("isLoggedIn", "true");
};